// import axios from '@/axios.js';
import { async } from '@team-decorate/vue-line-login'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import router from '@/router'
import _ from 'lodash'
import moment from 'moment-timezone'
const axios = require('axios').default
const qs = require('qs')
export default {
  async getGames ({ commit, state }, user) {
    let favorite = []
    if (user && user.user && user.user.key) {
      favorite = (await firebase.database().ref(`/favorite/${user.user.key}`).once('value')).val()
    }
    if (!Object.keys(state.games).lengths) {
      const { data } = await axios.get('https://api.icon1688.co/api/getgames')
      const games = data
      const gamesCustom = (await firebase.database().ref('/games_custom').once('value')).val()
      const newGames = []
      const hotGames = []
      const casinos = []
      const fishGames = []
      const slotGames = []
      const favoriteGames = []
      const cardGames = []
      const allgames = []
      for (const type in games) {
        for (const provider in games[type]) {
          for (const x in games[type][provider].games) {
            const game = games[type][provider].games[x]
            if (gamesCustom[game.id] && gamesCustom[game.id].hotgame && gamesCustom[game.id].hotgame === 'Y' && !_.find(hotGames, function (o) { return o.id === game.id })) {
              hotGames.push(game)
            }
            if (gamesCustom[game.id] && gamesCustom[game.id].newgame && gamesCustom[game.id].newgame === 'Y' && !_.find(newGames, function (o) { return o.id === game.id })) {
              newGames.push(game)
            }
            if (favorite && favorite[game.id] && !_.find(favoriteGames, function (o) { return o.id === game.id })) {
              const gameF = game
              gameF.createdate = favorite[game.id].createdate
              favoriteGames.push(gameF)
            }
            if (type === 'CARD') {
              cardGames.push(games[type][provider].games[x])
            }
            allgames.push(game)
          }
          if (type === 'SLOT' && provider === 'PGS') {
            const slot = games[type][provider]
            slot.order = 1
            slotGames.push(slot)
          } else if (type === 'SLOT' && provider === 'JOK') {
            const slot = games[type][provider]
            slot.order = 2
            slotGames.push(slot)
          } else if (type === 'SLOT' && provider === 'JL') {
            const slot = games[type][provider]
            slot.order = 3
            slotGames.push(slot)
          } else if (type === 'SLOT' && provider === 'CQ9') {
            const slot = games[type][provider]
            slot.order = 4
            slotGames.push(slot)
          } else if (type === 'SLOT' && provider === 'PMTS') {
            const slot = games[type][provider]
            slot.order = 5
            slotGames.push(slot)
          } else if (type === 'SLOT') {
            const slot = games[type][provider]
            slot.order = 6
            slotGames.push(slot)
          } else if (type === 'CASINO') {
            const casino = games[type][provider]
            // casino.order = 6;
            casinos.push(casino)
          } else if (type === 'FISH') {
            const fish = games[type][provider]
            // casino.order = 6;
            fishGames.push(fish)
          }
        }
      }
      if (favoriteGames.length) {
        commit('SET_FAVORITEGAMES', _.orderBy(favoriteGames, ['createdate'], ['desc']))
      }
      commit('SET_CARDGAMES', cardGames)
      commit('SET_CASINOGAMES', casinos)
      commit('SET_HOTGAMES', hotGames)
      commit('SET_NEWGAMES', newGames)
      commit('SET_FISHGAMES', fishGames)
      commit('SET_SLOTBRANDS', _.orderBy(slotGames, ['order'], ['asc']))
      commit('SET_GAMES', games)
      commit('SET_ALLGAMES', allgames)
    } else if (favorite.length) {
      const games = state.games
      const favoriteGames = []
      for (const type in games) {
        for (const provider in games[type]) {
          for (const x in games[type][provider].games) {
            const game = games[type][provider].games[x]
            if (favorite && favorite[game.id] && !_.find(favoriteGames, function (o) { return o.id === game.id })) {
              const gameF = game
              gameF.createdate = favorite[game.id].createdate
              favoriteGames.push(gameF)
            }
          }
        }
      }
      if (favoriteGames.length) {
        commit('SET_FAVORITEGAMES', _.orderBy(favoriteGames, ['createdate'], ['desc']))
      }
    }
  },
  async playGame ({ commit, state }, { user, id, provider, type, mobile }) {
    if (user.key && id && provider) {
      const data = qs.stringify({
        key: user.key,
        code: user.code,
        id,
        provider,
        mobile,
        type
      })
      await axios({
        method: 'post',
        url: 'https://api.icon1688.co/api/playgame',
        data: data
      }).then(async res => {
        if (res.data.status && res.data.uri) {
          commit('SET_PLAYGAME', res.data.uri)
          localStorage.setItem('gameURL', res.data.uri)
          router.replace('/playgame')
        }
      })
    }
  },
  async setUser ({ commit, state, dispatch }, user) {
    if (user && user.key) {
      const _user = (await firebase.database().ref(`/users/${user.key}`).once('value')).val()
      if (!_user || (_user && _user.status === 'N')) {
        firebase.auth().signOut()
        localStorage.removeItem('userData')
      }else{
        _user.key = user.key
        commit('SET_USER', _user)
        if (state.ref) state.ref.off()
        state.ref = firebase.database().ref('users/' + user.key)
        state.ref.on('child_added', snapshot => {
          if (snapshot.key === 'refreshCredit') {
            const credit = snapshot.val()
            if (credit.afterCredit > credit.beforeCredit) {
              commit('SET_CREDIT_INCREASE', true)
            } else {
              commit('SET_CREDIT_DECREASE', true)
            }
            dispatch('refreshCreditAll')
            firebase.database().ref('users/' + user.key).update({
              refreshCredit: null
            })
          }
        })
        state.ref.on('child_changed', snapshot => {
          if (snapshot.key === 'status' && snapshot.val() === 'N') {
            firebase.auth().signOut()
            localStorage.removeItem('userData')
            router.replace('/')
          } else if (snapshot.key === 'coin') {
            state.user[snapshot.key] = snapshot.val()
            commit('SET_USER', _user)
          }
        })
      }
    } else {
      commit('SET_USER', [])
    }
  },
  async getMinigames ({ commit }) {
    const minigames = (await firebase.database().ref('/games_web').orderByChild('status').equalTo(true).once('value')).val()
    const games = []
    for (const x in minigames) {
      const game = minigames[x]
      game.id = x
      games.push(game)
    }
    commit('SET_MINIGAMES', games)// _.orderBy(games, ['order'], ['asc'])
  },
  async getBanners ({ commit }) {
    const banners = (await firebase.database().ref('/banners').orderByChild('status').equalTo('Y').once('value')).val()
    commit('SET_BANNERS', _.orderBy(banners, ['order'], ['asc']))
  },
  async getSlips ({ commit, state }) {
    const slips = (await firebase.database().ref('/slips').once('value')).val()
    commit('SET_SLIPS', _.orderBy(slips, ['order'], ['asc']))
  },
  async getRewards ({ commit }) {
    const rewards = (await firebase.database().ref('/rewards').orderByChild('status').equalTo('Y').once('value')).val()
    for (const x in rewards) {
      rewards[x].key = x
    }
    commit('SET_REWARDS', _.orderBy(rewards, ['point'], ['asc']))
  },
  async getSites ({ commit, state }) {
    const sites = (await firebase.database().ref('/sites').orderByChild('used').equalTo(true).once('value')).val()
    commit('SET_SITES', _.orderBy(sites, ['order'], ['asc']))
    if (state.refsite) state.refsite.off()
    state.refsite = firebase.database().ref('sites').orderByChild('used').equalTo(true)
    state.refsite.on('child_changed', snapshot => {
      for (const x in state.sites) {
        if (state.sites[x].key === snapshot.val().key) {
          state.sites[x].status = snapshot.val().status
        }
      }
      commit('SET_SITES', state.sites)
    })
  },
  async refreshCreditAll ({ commit, state }, check) {
    let key = state.user.key
    if (!key) {
      const user = localStorage.getItem('userData')
      if (user) {
        const _user = JSON.parse(user)
        key = _user.key
      }
    }
    const { data } = await axios.get('https://api.icon1688.co/api/getbalance/' + key)
    if (data.status) {
      // if (check && check.checkamb && data.credit && data.amb && data.amb.credit && data.credit === data.amb.credit) {
      //   data.amb.credit = 0
      // }
      data.credit = data['ambking'].credit
      commit('SET_SITES_CREDIT', data)
    }
  },
  async moveCreditToWallet ({ dispatch, state }) {
    let key = state.user.key
    if (!key) {
      const user = localStorage.getItem('userData')
      if (user) {
        const _user = JSON.parse(user)
        key = _user.key
      }
    }
    const { data } = await axios.get('https://api.icon1688.co/api/movetowallet/' + key)
    if (data.status) {
      dispatch('refreshCreditAll', { checkamb: true })
    }
  },
  async getLastBonus ({ commit }, user) {
    const response = { status: false, bonus: 0, canwithdraw: 0, turncredit: 0 }
    await firebase.firestore().collection('krungsri').where('userkey', '==', user.key).where('status', '==', 'ดำเนินการแล้ว').orderBy('createdate', 'desc').limit(1).get().then((snapshot) => {
      snapshot.forEach((doc) => {
        response.bonus = (doc.data().bonus) ? doc.data().bonus : 0
        response.canwithdraw = (doc.data().canwithdraw) ? doc.data().canwithdraw : 0
        response.turncredit = (doc.data().turncredit) ? doc.data().turncredit : 0
      })
    })
    response.status = true
    commit('SET_LAST_BONUS', response)
  },
  async checkPromotions ({ commit, state }) {
    let promotions = {}
    let pro = false
    // สมัครสมาชิกใหม่
    await firebase.firestore().collection('krungsri').where('userkey', '==', state.user.key).orderBy('createdate', 'asc').limit(2).get().then(async (snapshot) => {
      let check = false
      if (!snapshot.size) {
        check = true
      } else if (snapshot.size === 1) {
        snapshot.forEach((doc) => {
          if (doc.data().bonus && (!doc.data().qty || doc.data().qty === 0)) {
            check = true
          }
        })
      }
      if (check) {
        await firebase.firestore().collection('promotions').where('type', '==', 'สมัครสมาชิกใหม่').where('status', '==', true).limit(1).get().then((snapshot2) => {
          snapshot2.forEach((doc) => {
            promotions = doc.data()
            pro = true
          })
        })
      }
    })
    // โปรพิเศษ
    if (!pro) {
      const time = moment().tz('Asia/Bangkok').format('HHmm')
      await firebase.firestore().collection('promotions').where('type', '==', 'โปรพิเศษ').where('status', '==', true).get().then(async (snapshot2) => {
        let promotionId = ''
        let checkMaxReceive = 0
        snapshot2.forEach((doc) => {
          const starttime = doc.data().starttime.replace(':', '')
          const endtime = doc.data().endtime.replace(':', '')
          if (Number(time) >= Number(starttime) && Number(time) <= Number(endtime)) {
            pro = true
            promotions = doc.data()
            promotionId = doc.id
            checkMaxReceive = doc.data().maxreceive
          }
        })
        if (checkMaxReceive) {
          await firebase.firestore().collection('promotion_users').where('userkey', '==', state.user.key).where('promotionId', '==', promotionId).where('date', '==', moment().tz('Asia/Bangkok').format('YYYY-MM-DD')).get().then(async (snapshot) => {
            if (snapshot.size >= checkMaxReceive) {
              pro = false
              promotions = {}
            }
          })
        }
      })
    }
    // ฝากแรกของวัน
    if (!pro) {
      await firebase.firestore().collection('krungsri').where('userkey', '==', state.user.key).where('createdate', '>=', moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + ' 00:00:00').limit(2).get().then(async (snapshot) => {
        let check = false
        if (!snapshot.size) {
          check = true
        } else if (snapshot.size === 1) {
          snapshot.forEach((doc) => {
            if (doc.data().bonus && (!doc.data().qty || doc.data().qty === 0)) {
              check = true
            }
          })
        }
        if (check) {
          await firebase.firestore().collection('promotions').where('type', '==', 'ฝากแรกของวัน').where('status', '==', true).get().then((snapshot2) => {
            snapshot2.forEach((doc) => {
              promotions = doc.data()
              pro = true
            })
          })
        }
      })
    }
    // โปรช่วงเวลา
    if (!pro) {
      const time = moment().tz('Asia/Bangkok').format('HHmm')
      await firebase.firestore().collection('promotions').where('type', '==', 'โปรช่วงเวลา').where('status', '==', true).get().then(async (snapshot2) => {
        snapshot2.forEach((doc) => {
          const starttime = doc.data().starttime.replace(':', '')
          const endtime = doc.data().endtime.replace(':', '')
          if (Number(time) >= Number(starttime) && Number(time) <= Number(endtime)) {
            pro = true
            promotions = doc.data()
          }
        })
      })
    }
    // โปรฝากทั้งวัน
    if (!pro) {
      await firebase.firestore().collection('promotions').where('type', '==', 'ฝากทั้งวัน').where('status', '==', true).get().then(async (snapshot2) => {
        snapshot2.forEach((doc) => {
          pro = true
          promotions = doc.data()
        })
      })
    }
    let gotpro = false
    if (pro) {
      const _user = (await firebase.database().ref(`/users/${state.user.key}`).once('value')).val()
      gotpro = (_user.bonus === 'Y')
    }
    commit('SET_PROMOTIONS', promotions)
    commit('SET_GOT_PRO', gotpro)
  },
  getJackpotGames ({ commit,state }) {
    const min = 10;
    const max = 99;
    const jackpot = state.jackpot;
    firebase.firestore().collection('games_jackpot').orderBy('jackpot', 'desc').onSnapshot(async (snapshot) => {
      const gamesall = []
      const data = []
      commit('SET_JACKPOTGAMES', [])
      snapshot.docChanges().forEach((change) => {
        data[change.doc.id] = change.doc.data();
        data[change.doc.id].key = change.doc.id;
        data[change.doc.id].type = change.type;
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
        data[change.doc.id].jackpot = Number(data[change.doc.id].jackpot +'.'+ randomNumber);
      });
      for(const x in data){
        if (data[x].type === "added") {
          const line = data[x];
          line.key = x;
          jackpot.push(line)
        }
        if (data[x].type === "modified") {
          const line = data[x];
          line.key = x;
          const index = jackpot.findIndex(x => x.key === line.key);
          jackpot[index] = line;
          if(jackpot[index].jackpot !== jackpot[index].oldjackpot){
            jackpot[index].oldjackpot = jackpot[index].jackpot;
            jackpot[index].animation = true;
          }else{
            jackpot[index].animation = false;
          }
        }
      }
      commit('SET_JACKPOTGAMES', jackpot)
    });
  }
}
