<template>
  <div>
    <headerSection mainMenu="event"></headerSection>
    <div id="main__content" class="">

<div class="x-cover x-index-event-container" style="padding-top: 0px;">
            <img src="../assets/images/cover-minigame-bg.jpg" class="-bg" alt="สูตรสล็อต สูตรบาคาร่า เจ้าแรกในไทย ผ่านเว็บตรง icon1688">
        <img src="../assets/images/cover-minigame-bg-mobile.jpg" class="-bg -mobile" alt="สูตรสล็อต สูตรบาคาร่า เจ้าแรกในไทย ผ่านเว็บตรง icon1688">

        <div class="container-fluid">
            <div class="row -contents-wrapper">
                <div class="col-12 col-md-6 -left-content"></div>
                <div class="col-12 col-md-6 -right-content bg-mobile">
                    <div data-slickable="{&quot;fade&quot;:true,&quot;arrows&quot;:false,&quot;autoplay&quot;:true,&quot;infinite&quot;:true,&quot;pauseOnHover&quot;:false,&quot;adaptiveHeight&quot;:true,&quot;slidesToShow&quot;:1,&quot;autoplaySpeed&quot;:5000}" data-animatable="fadeInUp" data-delay="200" class="slick-initialized slick-slider animated fadeInUp">
                                                    <div class="slick-list draggable" style="height: 58px;"><div class="slick-track" style="opacity: 1; width: 4495px; top: 5px; display: flex; justify-content: flex-end; float: right;">
                                                    <div class="slick-slide slick-current slick-active resize bg-mobile2" data-slick-index="0" aria-hidden="false" style="position: relative; left: 0px; top: 0px; z-index: 999; opacity: 1;">
                                                                    <h1 class="-title">กิจกรรมเพียบ <br>  จัดหนัก แรงที่สุดในตอนนี้</h1>
                                                                <p class="-sub-title">เกมส์เยอะโปรโมชั่นดี อันดับ 1 ของจริง ไม่ใช่ทุกคนที่ทำได้</p>
                                <div class="-btn-wrapper d-none animated fadeInUp" data-animatable="fadeInUp" data-delay="500">
                                    <a href="javascript:void(0)" class="btn -register-btn order-0" data-toggle="modal" data-target="#registerModal" tabindex="0">
    <span class="-btn-title">สมัครสมาชิก</span>
</a>
                                </div>
                            </div></div></div>
                                            </div>
                </div>
            </div>
        </div>
    </div>
    <div class="x-event-index-container">
        <div class="container">
            <div class="row -event-row-wrapper">
                <div class="col-lg-4 col-6 -col-wrapper" v-for="(item,index) in minigames">
                    <div class="-card-item animated fadeInUp" data-animatable="fadeInUp" data-delay="200">
                        <a href="javascript:void(0)" class="d-block h-100 -card-link-wrapper" @click="gotoMinigame(item)">
                            <div class="x-card card -multi-card">
                                <div class="-img-container">
                                    <img :src="item.logo" :alt="item.name" class="-img">
                                </div>
                                <div class="card-body">
                                    <div class="-title-container m-3">
                                        <h3 class="-title">{{item.name}}</h3>
                                        <p class="-sub-title">{{item.detail}}</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

<!-- <div class="x-modal modal -v2 -with-half-size" id="loginModal" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".js-modal-content" data-ajax-modal-always-reload="true">
                            <div class="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable -dialog-in-tab" role="document">
            <div class="modal-content -modal-content">
                                    <button type="button" class="close f-1 -in-tab" data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                                    <div class="x-modal-account-security-tabs -v2">
    <button type="button" class="-btn -register js-modal-account-security-tab-button " data-modal-target="#registerModal">
        สมัครสมาชิก
    </button>
    <button type="button" class="-btn -login js-modal-account-security-tab-button -active" data-modal-target="#loginModal">
        เข้าสู่ระบบ
    </button>
</div>

                                <div class="modal-body -modal-body">
                        <div class="x-modal-separator-container x-login-form">
    <div class="-top ">
                    <div data-animatable="fadeInModal" class="animated fadeInModal">
                <div class="-img-container text-center">

            <img class="-ic-login img-fluid lazyload" alt="login" width="60" height="60" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-slot-ic_login.png?v=1" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7">

                </div>
                <form action="/login-json-check" class="js-login-form">
                    <div class="-x-input-icon mt-3 mb-3 flex-column">

            <img class="-icon img-fluid lazyload" alt="username" width="15" height="15" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-slot-ic_phone.png?v=1" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7">
                            <input type="text" id="username" inputmode="text" name="username" pattern="[0-9]*" autofocus="" class="form-control x-form-control" placeholder="เบอร์โทรศัพท์">
                    </div>
                    <div class="-x-input-icon flex-column">

            <img class="-icon img-fluid lazyload" alt="password" width="15" height="15" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-slot-ic-lock-input.png?v=1" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7">
                            <input type="password" id="password" name="password" class="form-control x-form-control" placeholder="รหัสผ่าน">
                    </div>
                    <div class="x-reset-pw-text-container -v2">
                        <a href="#resetPassword" data-dismiss="modal" data-toggle="modal" data-target="#resetPasswordModal">
                            <u>ลืมรหัสผ่าน</u>
                        </a>
                    </div>

                    <div class="text-center">
                        <button type="submit" class="btn btn-primary -submit">
                            <span>เข้าสู่ระบบ</span>
                        </button>
                    </div>
                </form>
            </div>
            </div>
    <div class="-bottom ">
                    <div data-animatable="fadeInModal" class="animated fadeInModal">

                                    <div class="x-admin-contact ">
                        <span class="x-text-with-link-component">
     <label class="-text-message ">พบปัญหา</label>
     <a href="https://lin.ee/yAqyOuq" class="-link-message " target="_blank" rel="noopener">
        <u>ติดต่อฝ่ายบริการลูกค้า</u>
    </a>
</span>
                    </div>
                            </div>
            </div>
</div>
                </div>
            </div>
        </div>
    </div>

<div class="x-modal modal -v2 -with-half-size" id="registerModal" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".modal-body" data-ajax-modal-always-reload="true" data-ajax-modal="/_ajax_/register" data-container="#registerModal">
                            <div class="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable -dialog-in-tab -register-index-dialog" role="document">
            <div class="modal-content -modal-content">
                                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                                                    <div class="modal-header -modal-header">
                                                    <h3 class="x-title-modal d-inline-block m-auto">
                                <span></span>                            </h3>
                                            </div>
                                                <div class="modal-body -modal-body">
                                            <div class="js-modal-content"></div>
                                    </div>
            </div>
        </div>
    </div>

<div class="x-modal modal -v2 -with-half-size" id="resetPasswordModal" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".modal-body" data-ajax-modal-always-reload="true" data-ajax-modal="/_ajax_/reset-password" data-container="#resetPasswordModal">
                            <div class="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable " role="document">
            <div class="modal-content -modal-content">
                                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                                                    <div class="modal-header -modal-header">
                                                    <h3 class="x-title-modal d-inline-block m-auto">
                                <span></span>                            </h3>
                                            </div>
                                                <div class="modal-body -modal-body">
                                            <div class="js-modal-content"></div>
                                    </div>
            </div>
        </div>
    </div>

<div class="x-modal modal -v2 -alert-modal animated fadeInRight" id="alertModal" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".js-modal-content" data-ajax-modal-always-reload="true" data-animatable="fadeInRight" data-delay="700" data-dismiss-alert="true">
                            <div class="modal-dialog -modal-size -v2  " role="document">
            <div class="modal-content -modal-content">
                                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                                                                <div class="modal-body -modal-body">
                        <div class="d-flex -alert-body">
        <div class="text-center mr-3 -alert-body-wrapper">
            <img src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-slot-ic-alert-success.png" alt="SUCCESS" class="-img-alert js-ic-success img-fluid">
            <img src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-slot-ic-alert-failed.png" alt="FAIL" class="-img-alert js-ic-fail img-fluid">
        </div>
        <div class="my-auto js-modal-content"></div>
    </div>
                </div>
            </div>
        </div>
    </div>

<div class="x-modal modal -v2 x-theme-switcher-v2" id="themeSwitcherModal" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".js-modal-content" data-ajax-modal-always-reload="true">
                            <div class="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable modal-dialog-centered" role="document">
            <div class="modal-content -modal-content">
                                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                                                                <div class="modal-body -modal-body">

    <div class="-theme-switcher-container">
                <div class="-inner-header-section">
            <a class="-link-wrapper" href="/">
                                    <picture><source type="image/webp" data-srcset="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-logo.webp"><source type="image/png?v=1" data-srcset="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-logo.png?v=1"><img alt="EZ Casino รวมคาสิโนออนไลน์ บาคาร่าสด สล็อต กีฬา และเครดิตฟรี" class="-logo lazyload" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-logo.png?v=1" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"></picture>
            </a>
        </div>

        <div class="-inner-top-body-section">
                            <div class="col-6 -wrapper-box">

            <button type="button" class="btn -btn-item x-transaction-button-v2 -deposit -top-btn -horizontal" data-toggle="modal" data-dismiss="modal" data-target="#depositModal">
                <img alt="EZ Casino รูปไอคอนฝากเงิน" class="img-fluid -icon-image" src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-ic-deposit.png?v=1">

                    <div class="-typo-wrapper">
                <div class="-title">ฝากเงิน</div>
                                    <div class="-sub-title">Deposit</div>
                            </div>

        </button>

                </div>
                            <div class="col-6 -wrapper-box">

            <button type="button" class="btn -btn-item x-transaction-button-v2 -withdraw -top-btn -horizontal" data-toggle="modal" data-dismiss="modal" data-target="#withdrawModal">
                <img alt="EZ Casino รูปไอคอนถอนเงิน" class="img-fluid -icon-image" src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-ic-withdraw.png?v=1">

                    <div class="-typo-wrapper">
                <div class="-title">ถอนเงิน</div>
                                    <div class="-sub-title">Withdraw</div>
                            </div>

        </button>

                </div>
                    </div>

        <div class="-inner-center-body-section">
                            <div class="col-6 -wrapper-box">

            <a href="/event" class="btn -btn-item -event-button -menu-center -horizontal">
                <picture><source type="image/webp" data-srcset="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-ic-event.webp"><source type="image/png?v=1" data-srcset="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-ic-event.png?v=1"><img alt="EZ Casino รูปไอคอนสิทธิพิเศษ" class="img-fluid -icon-image lazyload" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-ic-event.png?v=1" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"></picture>

                    <div class="-typo-wrapper">
                <div class="-typo -is-logged">สิทธิพิเศษ</div>
                            </div>

        </a>

                </div>
                            <div class="col-6 -wrapper-box">

            <a href="/promotions" class="btn -btn-item -promotion-button -menu-center -horizontal">
                <picture><source type="image/webp" data-srcset="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-ic-promotion.webp"><source type="image/png?v=1" data-srcset="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-ic-promotion.png?v=1"><img alt="EZ Casino รูปไอคอนโปรโมชั่น" class="img-fluid -icon-image lazyload" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-ic-promotion.png?v=1" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"></picture>

                    <div class="-typo-wrapper">
                <div class="-typo -is-logged">โปรโมชั่น</div>
                            </div>

        </a>

                </div>
                            <div class="col-6 -wrapper-box">

            <a href="https://ezmovie.co/" class="btn -btn-item -movie-button -menu-center -horizontal" target="_blank" rel="noopener nofollow">
                <picture><source type="image/webp" data-srcset="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-ic-movie.webp"><source type="image/png?v=1" data-srcset="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-ic-movie.png?v=1"><img alt="EZ Casino รูปไอคอนดูหนัง" class="img-fluid -icon-image lazyload" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-ic-movie.png?v=1" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"></picture>

                    <div class="-typo-wrapper">
                <div class="-typo -is-logged">ดูหนัง</div>
                            </div>

        </a>

                </div>
                            <div class="col-6 -wrapper-box">

            <a href="https://lin.ee/yAqyOuq" class="btn -btn-item -line-button -menu-center -horizontal" target="_blank" rel="noopener nofollow">
                <picture><source type="image/webp" data-srcset="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-ic-line.webp"><source type="image/png?v=1" data-srcset="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-ic-line.png?v=1"><img alt="EZ Casino รูปไอคอนดูหนัง" class="img-fluid -icon-image lazyload" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-ic-line.png?v=1" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"></picture>

                    <div class="-typo-wrapper">
                <div class="-typo -is-logged">Line</div>
                            </div>

        </a>

                </div>
                    </div>

        <div class="-inner-bottom-body-section">
                            <div class="col-12 -wrapper-box">

            <a href="https://ezcasino.bet/change-web?_t=5bbdlrvius4375hegp9se76ij1&amp;_sign=e7c5e43472b6257a720f12621e0eba6c706641d6500aab9fdb14b7e65766f1212e418186e97a9567f5b57377eca63bdba597b607a6bf6eeda463c8f3b2492a78" class="btn -btn-item -ezslot-button -menu-banner " target="_blank" rel="ugc,nofollow">
                <img alt="EZ Casino รูปแบนเนอร์ ez-slot" class="img-fluid -banner-image" src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-banner-ez-slot.png?v=1">

        </a>

                </div>
                            <div class="col-12 -wrapper-box">

            <a href="#share1theme-switcher" class="btn -btn-item -social-share-button -menu-banner " data-ajax-modal-ondemand-user="social-share-modal-1" data-force="true" data-x-dismiss="true" data-parent-class-selector="x-social-share-main-modal -v2" data-url="/account/_ajax_/social-share-submit/1/create">
                <img alt="EZ Casino รูปแบนเนอร์แชร์แลกทรัพย์" class="img-fluid -banner-image" src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-banner-social-share.png?v=1">

        </a>

                </div>
                            <div class="col-12 -wrapper-box">

            <a href="#0" class="btn -btn-item -bookmark-button -menu-banner  js-add-to-home-screen">
                <img alt="EZ Casino รูปแบนเนอร์ bookmark" class="img-fluid -banner-image" src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-switcher-banner-bookmark.png?v=1">

        </a>

                </div>
                    </div>
    </div>
                </div>
            </div>
        </div>
    </div>

<div class="x-modal modal -v2 " id="bookmarkModal" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".js-modal-content" data-ajax-modal-always-reload="true">
                            <div class="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable         -no-fixed-button
    " role="document">
            <div class="modal-content -modal-content">
                                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                                                    <div class="modal-header -modal-header">
                                                    <h3 class="x-title-modal d-inline-block m-auto">
                                <span>Bookmark</span>                            </h3>
                                            </div>
                                                <div class="modal-body -modal-body">

        <div class="x-bookmark-modal-container">
            <nav>
                <div class="nav nav-tabs x-bookmark-tabs-header-wrapper" id="nav-tab" role="tablist">
                                                                    <a class="nav-link " id="nav-android-tab" data-toggle="tab" href="#nav-android" role="tab" aria-controls="nav-android" aria-selected="true">Android</a>
                                                                    <a class="nav-link active" id="nav-ios-tab" data-toggle="tab" href="#nav-ios" role="tab" aria-controls="nav-ios" aria-selected="true">iOS</a>
                                    </div>
            </nav>

            <div class="tab-content x-bookmark-tabs-content-wrapper" id="nav-tabContent">

                    <div class="tab-pane fade " id="nav-android" role="tabpanel" aria-labelledby="nav-android-tab">
                        <div class="-slide-wrapper -bookmark-slider-for-android slick-initialized slick-slider slick-dotted" data-slickable="{&quot;arrows&quot;:false,&quot;dots&quot;:true,&quot;slidesToShow&quot;:1,&quot;fade&quot;:true,&quot;infinite&quot;:true,&quot;autoplay&quot;:false,&quot;asNavFor&quot;:&quot;.-bookmark-slider-nav-android&quot;}">
                                                            <div class="slick-list draggable"><div class="slick-track" style="opacity: 1; width: 0px;"><div class="-slide-inner-wrapper slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" role="tabpanel" id="slick-slide30" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 999; opacity: 1;">
                                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-android-1.png" class="-img lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="250" height="538">
                                </div><div class="-slide-inner-wrapper slick-slide" data-slick-index="1" aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide31" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-android-2.png" class="-img lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="250" height="538">
                                </div><div class="-slide-inner-wrapper slick-slide" data-slick-index="2" aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide32" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-android-3.png" class="-img lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="250" height="538">
                                </div></div></div>

                                                    <ul class="slick-dots" style="" role="tablist"><li class="slick-active" role="presentation"><button type="button" role="tab" id="slick-slide-control30" aria-controls="slick-slide30" aria-label="1 of 3" tabindex="0" aria-selected="true">1</button></li><li role="presentation"><button type="button" role="tab" id="slick-slide-control31" aria-controls="slick-slide31" aria-label="2 of 3" tabindex="-1">2</button></li><li role="presentation"><button type="button" role="tab" id="slick-slide-control32" aria-controls="slick-slide32" aria-label="3 of 3" tabindex="-1">3</button></li></ul></div>

                        <div class="-slide-wrapper -bookmark-slider-nav-android slick-initialized slick-slider" data-slickable="{&quot;arrows&quot;:false,&quot;dots&quot;:false,&quot;slidesToShow&quot;:1,&quot;fade&quot;:true,&quot;infinite&quot;:true,&quot;autoplay&quot;:false,&quot;asNavFor&quot;:&quot;.-bookmark-slider-for-android&quot;}">
                                                            <div class="slick-list draggable"><div class="slick-track" style="opacity: 1; width: 0px;"><div class="-slide-inner-wrapper px-3 pt-3 slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 999; opacity: 1;">
                                    <div class="-content-wrapper -center">
                                                                                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-slide-number-1.png" class="-icon lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="45" height="70">
                                                                                <div class="-description">เข้า Google Chrome แล้ว Search <br> “ezslot” เข้าสู่หน้าเว็บ</div>
                                    </div>
                                </div><div class="-slide-inner-wrapper px-3 pt-3 slick-slide" data-slick-index="1" aria-hidden="true" tabindex="-1" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                    <div class="-content-wrapper -center">
                                                                                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-slide-number-2.png" class="-icon lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="45" height="70">
                                                                                <div class="-description">เลือก “ติดตั้ง”</div>
                                    </div>
                                </div><div class="-slide-inner-wrapper px-3 pt-3 slick-slide" data-slick-index="2" aria-hidden="true" tabindex="-1" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                    <div class="-content-wrapper -center">
                                                                                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-slide-number-3.png" class="-icon lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="45" height="70">
                                                                                <div class="-description">ตรวจสอบหน้า <br> โฮมสกรีนว่ามีไอคอนขึ้นแล้ว</div>
                                    </div>
                                </div></div></div>

                                                    </div>
                    </div>

                    <div class="tab-pane fade show active" id="nav-ios" role="tabpanel" aria-labelledby="nav-ios-tab">
                        <div class="-slide-wrapper -bookmark-slider-for-ios slick-initialized slick-slider slick-dotted" data-slickable="{&quot;arrows&quot;:false,&quot;dots&quot;:true,&quot;slidesToShow&quot;:1,&quot;fade&quot;:true,&quot;infinite&quot;:true,&quot;autoplay&quot;:false,&quot;asNavFor&quot;:&quot;.-bookmark-slider-nav-ios&quot;}">
                                                            <div class="slick-list draggable"><div class="slick-track" style="opacity: 1; width: 0px;"><div class="-slide-inner-wrapper slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" role="tabpanel" id="slick-slide50" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 999; opacity: 1;">
                                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-ios-1.png" class="-img lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="250" height="538">
                                </div><div class="-slide-inner-wrapper slick-slide" data-slick-index="1" aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide51" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-ios-2.png" class="-img lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="250" height="538">
                                </div><div class="-slide-inner-wrapper slick-slide" data-slick-index="2" aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide52" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-ios-3.png" class="-img lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="250" height="538">
                                </div><div class="-slide-inner-wrapper slick-slide" data-slick-index="3" aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide53" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-ios-4.png" class="-img lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="250" height="538">
                                </div></div></div>

                                                    <ul class="slick-dots" style="" role="tablist"><li class="slick-active" role="presentation"><button type="button" role="tab" id="slick-slide-control50" aria-controls="slick-slide50" aria-label="1 of 4" tabindex="0" aria-selected="true">1</button></li><li role="presentation"><button type="button" role="tab" id="slick-slide-control51" aria-controls="slick-slide51" aria-label="2 of 4" tabindex="-1">2</button></li><li role="presentation"><button type="button" role="tab" id="slick-slide-control52" aria-controls="slick-slide52" aria-label="3 of 4" tabindex="-1">3</button></li><li role="presentation"><button type="button" role="tab" id="slick-slide-control53" aria-controls="slick-slide53" aria-label="4 of 4" tabindex="-1">4</button></li></ul></div>

                        <div class="-slide-wrapper -bookmark-slider-nav-ios slick-initialized slick-slider" data-slickable="{&quot;arrows&quot;:false,&quot;dots&quot;:false,&quot;slidesToShow&quot;:1,&quot;fade&quot;:true,&quot;infinite&quot;:true,&quot;autoplay&quot;:false,&quot;asNavFor&quot;:&quot;.-bookmark-slider-for-ios&quot;}">
                                                            <div class="slick-list draggable"><div class="slick-track" style="opacity: 1; width: 0px;"><div class="-slide-inner-wrapper px-3 pt-3 slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 999; opacity: 1;">
                                    <div class="-content-wrapper -center">
                                                                                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-slide-number-1.png" class="-icon lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="45" height="70">
                                                                                <div class="-description">เข้า Safari แล้ว Search “ezslot” <br> เข้าสู่หน้าเว็บ กดที่ <i class="fa-solid fa-arrow-up-from-square"></i></div>
                                    </div>
                                </div><div class="-slide-inner-wrapper px-3 pt-3 slick-slide" data-slick-index="1" aria-hidden="true" tabindex="-1" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                    <div class="-content-wrapper -center">
                                                                                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-slide-number-2.png" class="-icon lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="45" height="70">
                                                                                <div class="-description">เลือก “เพิ่มลงใปยังหน้าจอโฮม”</div>
                                    </div>
                                </div><div class="-slide-inner-wrapper px-3 pt-3 slick-slide" data-slick-index="2" aria-hidden="true" tabindex="-1" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                    <div class="-content-wrapper -center">
                                                                                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-slide-number-3.png" class="-icon lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="45" height="70">
                                                                                <div class="-description">กด “เพิ่ม”ทางลัดเข้าสู่เกมส์ <br> ลงในหน้าจอโฮม</div>
                                    </div>
                                </div><div class="-slide-inner-wrapper px-3 pt-3 slick-slide" data-slick-index="3" aria-hidden="true" tabindex="-1" style="width: 0px; position: relative; left: 0px; top: 0px; z-index: 998; opacity: 0;">
                                    <div class="-content-wrapper -center">
                                                                                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="https://ezslot.bet/build/web/ez-bet-v2/img/ez-theme-bookmark-slide-number-4.png" class="-icon lazyload" alt="บาคาร่าออนไลน์ คาสิโนออนไลน์ อันดับ 1 ของไทย" width="45" height="70">
                                                                                <div class="-description">ตรวจสอบหน้า <br> โฮมสกรีนว่ามีไอคอนขึ้นแล้ว</div>
                                    </div>
                                </div></div></div>

                                                    </div>
                    </div>
                            </div>
        </div>

                    </div>
            </div>
        </div>
    </div> -->

<!--
<div class="x-modal modal -v2 " id="accountModal" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".modal-body" data-ajax-modal-always-reload="true" data-ajax-modal="/account/_ajax_/customer-info" data-container="#accountModal">
                            <div class="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable -modal-big" role="document">
            <div class="modal-content -modal-content">
                                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                                                                <div class="modal-body -modal-body">
                                            <div class="js-modal-content"></div>
                                    </div>
            </div>
        </div>
    </div>

<div class="x-modal modal -v2 " id="accountModalMobile" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".modal-body" data-ajax-modal-always-reload="true" data-ajax-modal="/account/_ajax_/customer-info?isMobileView=1" data-container="#accountModalMobile">
                            <div class="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable " role="document">
            <div class="modal-content -modal-content">
                                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                                                                <div class="modal-body -modal-body">
                                            <div class="js-modal-content"></div>
                                    </div>
            </div>
        </div>
    </div>

<div class="x-modal modal -v2 " id="providerUserModalMobile" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".modal-body" data-ajax-modal-always-reload="true" data-ajax-modal="/account/_ajax_/provider-user-info?isMobileView=1" data-container="#providerUserModalMobile">
                            <div class="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable " role="document">
            <div class="modal-content -modal-content">
                                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                                                                <div class="modal-body -modal-body">
                                            <div class="js-modal-content"></div>
                                    </div>
            </div>
        </div>
    </div>

<div class="x-modal modal -v2 " id="couponModalMobile" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".modal-body" data-ajax-modal-always-reload="true" data-ajax-modal="/account/_ajax_/coupon-apply?isMobileView=1" data-container="#couponModalMobile">
                            <div class="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable " role="document">
            <div class="modal-content -modal-content">
                                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                                                                <div class="modal-body -modal-body">
                                            <div class="js-modal-content"></div>
                                    </div>
            </div>
        </div>
    </div>

<div class="x-modal modal -v2 " id="joinPromotionModalMobile" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".modal-body" data-ajax-modal-always-reload="true" data-ajax-modal="/account/_ajax_/promotion?isMobileView=1" data-container="#joinPromotionModalMobile">
                            <div class="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable " role="document">
            <div class="modal-content -modal-content">
                                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                                                                <div class="modal-body -modal-body">
                                            <div class="js-modal-content"></div>
                                    </div>
            </div>
        </div>
    </div>

<div class="x-modal modal -v2 -with-backdrop -with-separator -with-half-size" id="depositModal" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".modal-body" data-ajax-modal-always-reload="true" data-ajax-modal="/account/_ajax_/deposit" data-container="#depositModal">
                            <div class="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable " role="document">
            <div class="modal-content -modal-content">
                                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                                                    <div class="modal-header -modal-header">
                                                    <h3 class="x-title-modal d-inline-block m-auto">
                                <span></span>                            </h3>
                                            </div>
                                                <div class="modal-body -modal-body">
                                            <div class="js-modal-content"></div>
                                    </div>
            </div>
        </div>
    </div>

<div class="x-modal modal -v2 -with-half-size" id="withdrawModal" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".modal-body" data-ajax-modal-always-reload="true" data-ajax-modal="/account/_ajax_/withdraw" data-container="#withdrawModal">
                            <div class="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable " role="document">
            <div class="modal-content -modal-content">
                                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                                                    <div class="modal-header -modal-header">
                                                    <h3 class="x-title-modal d-inline-block m-auto">
                                <span></span>                            </h3>
                                            </div>
                                                <div class="modal-body -modal-body">
                                            <div class="js-modal-content"></div>
                                    </div>
            </div>
        </div>
    </div>

<div class="x-modal modal -v2 " id="depositChoosePromotionModal" tabindex="-1" role="dialog" aria-hidden="true" data-loading-container=".modal-body" data-ajax-modal-always-reload="true" data-ajax-modal="/account/_ajax_/promotions/in-deposit" data-container="#depositChoosePromotionModal">
                            <div class="modal-dialog -modal-size -v2 modal-dialog-centered modal-dialog-scrollable " role="document">
            <div class="modal-content -modal-content">
                                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                                                    <div class="modal-header -modal-header">
                                                    <h3 class="x-title-modal d-inline-block m-auto">
                                <span></span>                            </h3>
                                            </div>
                                                <div class="modal-body -modal-body">
                                            <div class="js-modal-content"></div>
                                    </div>
            </div>
        </div>
    </div> -->

<footerSection></footerSection>

            </div>

</div>
</template>

<script>
import router from './../router'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import moment from 'moment-timezone'
const axios = require('axios').default
export default {
  name: 'Home',
  components: {
    headerSection: () => import('./Header.vue'),
    footerSection: () => import('./Footer.vue')
  },
  mounted () {
    // Bonn.boots.push(function() {
    //   setTimeout(function() {
    //     $('#bankInfoModal').modal('show');
    //   }, 500);
    // });
    this.$store.dispatch('user/getMinigames').catch(err => { console.error(err) })
    // this.checkAnoucement()
  },
  computed: {
    user () {
      return this.$store.state.user.user
    },
    minigames () {
      return this.$store.state.user.minigames
    },
    sites_credit () {
      return this.$store.state.user.sites_credit
    }
  },
  data () {
    return {
      receiveAnounceMent: false
    }
  },
  methods: {
    async gotoMinigame (item) {
      if (this.user && this.user.key) {
        if (item.id === '3boxs') {
          router.replace('/boxs')
        } else if (item.id === 'checkin') { // && item.deposit == '0'
          this.$store.dispatch('user/refreshCreditAll').finally(async (res) => {
            if (item.turn && Number(item.turn) > 0 && this.sites_credit && Number(this.sites_credit.credit) > 5) {
              this.$swal({ icon: 'error', title: 'Error!', text: 'พี่จ๋ามีมีมากกว่า 4 เครดิต ไม่สามารถเล่นได้นะคะ!!!' })
              return false
            }

            if ((item.deposit && this.user.games && Number(this.user.games.checkin) > 0) || item.deposit === '0') {
              await firebase.firestore().collection('spin').doc(`${this.user.key}-${moment().tz('Asia/Bangkok').format('YYYY-MM-DD')}-checkin`).get().then((snapshot) => {
                if (snapshot.exists) {
                  this.$swal({ icon: 'error', title: 'Error!', text: 'ท่าน Check-in วันนี้แล้วนะคะรอพรุ่งนี้นะคะ!!!' })
                } else {
                  axios({
                    method: 'post',
                    url: 'https://api.icon1688.co/qspin/checkin',
                    data: {
                      key: this.user.key,
                      code: this.user.code,
                      value: Number(item.from),
                      type: item.type,
                      cal: (item.cal) ? item.cal : 'manual'
                    }
                  })
                  setTimeout(() => {
                    this.$swal({ icon: 'success', title: 'Success', text: 'ท่าน Check-in เรียบร้อยแล้วค่ะ' })
                  }, 1000)
                }
              })
            } else {
              await firebase.firestore().collection('spin').doc(`${this.user.key}-${moment().tz('Asia/Bangkok').format('YYYY-MM-DD')}-checkin`).get().then((snapshot) => {
                if (snapshot.exists) {
                  this.$swal({ icon: 'error', title: 'Error!', text: 'ท่าน Check-in วันนี้แล้วนะคะรอพรุ่งนี้นะคะ!!!' })
                } else {
                  this.$swal({ icon: 'error', title: 'Error!', text: 'โปรดตรวจสอบสิทธิ์การ Check-in!' })
                }
              })
            }
          }).catch(err => { console.error(err); this.fa_spin = false })
        } else if (item.id === 'code') {
            this.$store.commit('user/SET_LOADING', true)
            this.$store.dispatch('user/refreshCreditAll').finally(async (res) => {
                if (item.turn && Number(item.turn) > 0 && this.sites_credit && Number(this.sites_credit.credit) > 5) {
                    this.$swal({ icon: 'error', title: 'Error!', text: 'พี่จ๋ามีมีมากกว่า 4 เครดิต ไม่สามารถเล่นได้นะคะ!!!' })
                    this.$store.commit('user/SET_LOADING', false)
                    return false
                }
                firebase.firestore().collection('codefree').doc(this.user.key).get().then((result)=>{
                    if(!result.exists){
                        this.$swal({ 
                            title: 'โปรดกรอกรหัส', 
                            input: 'text', 
                            inputAttributes: {
                                autocapitalize: 'off'
                            },
                            showCancelButton: true,
                            confirmButtonText: 'ตกลง',
                            cancelButtonText: 'ยกเลิก',
                            showLoaderOnConfirm: true,
                            preConfirm: async (login) => {
                                if(item.typecode === 'โค้ดซ้ำ' && item.code !== login){
                                    this.$swal({ icon: 'error', title: 'Error!', text: 'โปรดตรวจสอบ Code ค่ะ!!' })
                                    this.$store.commit('user/SET_LOADING', false)
                                }else{
                                    setTimeout(() => {
                                        axios({
                                            method: 'post',
                                            url: 'https://api.icon1688.co/qspin/code',
                                            data: {
                                                key: this.user.key,
                                                code: this.user.code,
                                                keycode: login,
                                            }
                                        });
                                    }, Math.floor(Math.random() * 3000));
                                    return true;
                                }
                            },
                            allowOutsideClick: () => !Swal.isLoading()
                        }).then(() => {
                            this.$store.commit('user/SET_LOADING', false)
                        })
                    }else{
                        this.$store.commit('user/SET_LOADING', false)
                        this.$swal({ icon: 'error', title: 'Error!', text: 'ท่านรับ Code ฟรีไปแล้ว!!' })
                    }
                })
            })
        } else {
          router.replace('/' + item.id)
        }
      }
    },
    getUserId () {
      const user = localStorage.getItem('userData')
      if (user) {
        const _user = JSON.parse(user)
        return _user
      } else {
        firebase.auth().signOut()
        localStorage.removeItem('userData')
        router.replace('/')
      }
    },
    nl2br (str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
        return ''
      }
      var breakTag =
        is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>'
      return (str + '').replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        '$1' + breakTag + '$2'
      )
    }
    // async moveCreditToWallet () {
    //   this.$store.commit('user/SET_LOADING', true)
    //   const user = this.getUserId()
    //   this.$store.dispatch('user/moveCreditToWallet').catch(err => { console.error(err) })
    //   this.$store.dispatch('user/getLastBonus', user).finally(res => {
    //     this.$store.commit('user/SET_LOADING', false)
    //     if (this.lastbonus.turncredit) {
    //       this.$store.commit('user/SET_ALERT_WITHDRAWALL', true)
    //     }
    //   }).catch(err => { console.error(err) })
    // },
  }
}
</script>
<style scoped>
@media screen and (max-width: 992px) {
    .resize {
        width: 899px;
    }
}
@media screen and (max-width: 600px) {
    .resize {
        width: 399px;
    }
}

.bg-mobile2{
  margin-right: 100px;
}
@media screen and (max-width: 992px) {
    .resize {
        width: 899px;
    }
}
@media screen and (max-width: 600px) {
    .resize {
        width: 399px;
    }
    .bg-mobile{
      background-color: #1c1c1c;
    }
    .bg-mobile2{
      margin-right: 0;
    }
}
</style>
