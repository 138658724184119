<template>
  <div style="width: 100%;" class="loginbody">
		<div class="preloader" v-if="loading">
        <div class="circular-spinner">
          <img src="../assets/images/shiba-loading.png">
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-5 mx-auto">
                <div id="first">
                    <div class="myform form ">
                        <div class="logo mb-3">
                            <div class="col-md-12 text-center">
                                <img src="../assets/images/logo-shiba888.png"
                                    style="max-width: 100%;">
                                <p class="mt-3 text-white small"></p>
                            </div>
                        </div>
                        <form id="frm-login" name="frm-login" method="post" onsubmit="return false;">
                            <input type="hidden" id="_token" name="tokencsrf"
                                value="126babe7225c2c5e7e51356f2eae4e4c">
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1" style="padding: 13px;">
                                            <i class="fa fa-user"></i>
                                        </span>
                                    </div>
                                    <input type="number" class="form-control" placeholder="เบอร์โทรศัพท์"
                                        v-model="mobile" id="mobile" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10"
                                        aria-describedby="basic-addon1">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon2" style="max-width: 37.4px;">
                                            <i class="fa fa-key" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <input type="password" id="password" name="password" class="form-control"
                                        placeholder="รหัสผ่าน" aria-label="password" v-model="password" aria-describedby="basic-addon2">
                                    <div class="input-group-append showpass" data-isshow="false">
                                        <span class="input-group-text"><i class="fa fa-eye-slash"
                                                aria-hidden="true"></i></span>
                                    </div>

                                </div>
                            </div>
                            <div class="form-group">
                                <p class="text-right">
                                    <a id="btn-forgotModal" href="javascript:;" data-toggle="modal" data-target="#forgotModal" style="color: #fff;">ลืมรหัสผ่าน ?</a> / <a href="javascript:;" @click="openLine()" style="color: #fff;">ติดต่อแอดมิน</a>
                                </p>
                            </div>
                            <div class="col-md-12 text-center ">
                                <button type="button" class="google btn mybtn" style="border: 1px solid lightgrey;" @click="login()">เข้าสู่ระบบ</button>
                            </div>
                            <!-- <div class="col-md-12 text-center" style="padding-top:15px;">
                                <button type="button" class="google btn mybtn cCsozd ewHin" @click="lineLogin()"><img class="icon" src="../assets/images/icon-line.svg" alt="line">เข้าสู่ระบบด้วยไลน์</button>
                            </div> -->
                            <div class="col-md-12 ">
                                <div class="login-or">
                                    <hr class="hr-or">
                                    <span class="span-or">หรือยังไม่มีบัญชี</span>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <p class="text-center">
                                    <router-link to="/register" class="btn btn-blu-grow">สมัครสมาชิก</router-link>
                                </p>
                            </div>
                            <!-- <div class="form-group">
                                <p class="text-center"><a href="https://shib1688.com/" id="signup" style="color: #aaa;"><i
                                            class="fa fa-reply" aria-hidden="true"></i> กลับไปหน้าแรก</a></p>
                            </div> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="forgotModal" tabindex="-1" role="dialog" aria-labelledby="forgotModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="forgotModalLabel" style="color: #fec33f;">เปลี่ยนรหัสผ่าน</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            style="color: #FFF;opacity: 1;font-weight: 400;">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body msformforgot">
                        <fieldset>
                            <p class="text-center">กรุณากรอกเบอร์โทรศัพท์และเลขที่บัญชี</p>
                            <div class="myform" style="padding: 0;background: none;">
                                <div class="form-group ">
                                    <div class="input-group mb-0">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">
                                                <i class="fa fa-user"></i>
                                            </span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="เบอร์โทรศัพท์" v-model="forgot_mobile"
                                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10"
                                            aria-describedby="basic-addon1">
                                    </div>
                                </div>

                                <div class="form-group " >
                                    <div class="input-group mb-0">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon2" style="padding: 0.375rem 9px;">
                                            <i class="fa fa-id-card-o" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="เลขที่บัญชี" id="accountnumber" name="accountnumber" v-model="forgot_account" onkeypress="global.onlyNumbersEnter(event)" aria-describedby="basic-addon2">
                                    </div>
                                </div>

                                <p class="text-center">กรุณากรอกรหัสผ่านใหม่</p>

								<div class="form-group">
                                    <div class="input-group mb-0">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon2">
                                                <i class="fa fa-key"></i>
                                            </span>
                                        </div>
                                        <input type="password" class="form-control" placeholder="รหัสผ่านใหม่"
                                            id="newpassword" name="newpassword" aria-label="email" v-model="forgot_password"
                                            aria-describedby="basic-addon2">
                                    </div>
                                </div>

								<div class="form-group">
                                    <div class="input-group mb-0">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon2">
                                                <i class="fa fa-key"></i>
                                            </span>
                                        </div>

                                        <input type="password" class="form-control" placeholder="ยืนยันรหัสผ่านใหม่"
                                            id="con_newpassword" name="con_newpassword" aria-label="email" v-model="forgot_password2"
                                            aria-describedby="basic-addon2">
                                    </div>
                                </div>

                            </div>

                            <div class="modal-footer" style="border-top: none;">
                                <button type="button" id="btn_change_password" class=" action-button btn btn-block mybtn icon  btn_login tx-tfm" @click="forgotPassword()">ยืนยัน</button>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
	  </div>
  </div>
</template>

<script>
import queryString from 'querystring'
import firebase from 'firebase/app'
import 'firebase/database'
import router from './../router'
const axios = require('axios').default
// import OAuthRequest from '@team-decorate/src/Entities/OAuthRequest'
const qs = require('qs')
// import OAuthResult from '@team-decorate/src/Entities/OAuthResult'
export default {
  name: 'Login',
  async created () {
    document.body.style.backgroundColor = "#232323";
    const { code } = queryString.parse(window.location.search.replace('?', ''))
    if (!code) return
    const result = await this.getToken(code)
    if (result && result.access_token) {
      const { data } = await this.getProfile(result.access_token)
      this.checkLineLogin(data)
    }
    // if(this.friendRequired) {
    //     const flag = await this.checkFriend(result.token)
    //     if(!flag) {
    //         this.error = this.friendErrorTest
    //     }
    // }
    // if(!this.error) {
    //     const response = Object.assign(data, result.getPostable())
    //     console.log(response);
    // }
  },
  data () {
    return {
      pictureUrl: '',
      name: '',
      logo: '',
      login_color: '#1e6ee8',
      error: '',
      link: '',
      mobile: '',
      password: '',
      web: [],
      change: false,
      loading: false,
      clientId: '1656935750',
      callbackUri: 'https://icon1688.co/login',
      clientSecret: '4b4c6d35256b0034244f1a0056ddb640',
      addFriend: false,
      friendRequired: false,
      friendErrorTest: '',
      forgot_mobile:'',
      forgot_account:'',
      forgot_password:'',
      forgot_password2:'',
    }
  },
  mounted () {
    // this.getSetting();
    $('body').on('click', '.showpass', function () {
      let isShow = $(this).attr('data-isshow')
      const pType = isShow == 'false' ? 'text' : 'password'
      isShow = isShow == 'false' ? 'true' : 'false'
      $(this).attr('data-isshow', isShow)
      if (pType == 'text') {
        $(this).find('i').attr('class', 'fa fa-eye')
      } else {
        $(this).find('i').attr('class', 'fa fa-eye-slash')
      }
      $('#password').attr('type', pType)
    })
    $('body').on('click', '#btn-forgotModal', function () {
      $('.msformforgot fieldset:not(:first-of-type)').css({ display: 'none' })

      const fEle = $('.msformforgot fieldset:first-child')
      fEle.css({ display: 'block', opacity: 1 })
    })
  },
  methods: {
    forgotPassword() {
      if(this.forgot_mobile && this.forgot_account && this.forgot_password && this.forgot_password2){
        if(this.forgot_password.trim() !== this.forgot_password2.trim()){
          this.$swal({ icon: 'error', title: 'ข้อความ!', text: 'โปรดตรวจสอบข้อมูลรหัสให้ถูกต้องค่ะ!!!' })
        }else{
          axios({
            method: 'post',
            url: 'https://api.icon1688.co/api/forgotpassword',
            data: {
              mobile: this.forgot_mobile.trim(),
              account: this.forgot_account.trim(),
              newpassword: this.forgot_password.trim()
            }
          }).then(async res => {
              if (res && res.data && res.data.status) {
                $("#forgotModal").modal('hide')
                this.$swal({ icon: 'success', title: 'ข้อความ!', text: 'เปลี่ยนรหัสผ่านเรียบร้อยแล้วค่ะ' })
              }else if(res.data.error){
                this.$swal({ icon: 'error', title: 'ข้อความ!', text: res.data.error })
              }
          });
        }
      }else{
        this.$swal({ icon: 'error', title: 'ข้อความ!', text: 'โปรดระบุข้อมูลให้ครบด้วยค่ะ!!!' })
      }
    },
    checkLineLogin (data) {
      this.loading = true
      axios({
        method: 'post',
        url: 'https://api.icon1688.co/api/loginline',
        data: {
          userId: data.userId,
          pictureUrl: data.pictureUrl
        }
      }).then(async res => {
        if (res && res.data && res.data.status) {
          firebase.auth().signInWithEmailAndPassword(res.data.mobile.trim().toLowerCase() + '@icon1688.co', res.data.password.trim()).then(async (res2) => {
            if (res2.user) {
              localStorage.setItem('userData', JSON.stringify(res.data))
              this.loading = false
              router.replace('/')
            } else {
              this.error = 'โปรดติดต่อแอดมิน @icon1688'
            }
            this.loading = false
          }).catch(() => {
            this.$swal({ icon: 'error', title: 'ข้อความ!', text: 'เบอร์โทรศัพท์ / รหัสผ่าน ไม่ถูกต้องค่ะ!!!' })
            this.loading = false
          })
        }else{
          localStorage.setItem('userId', JSON.stringify(data))
          router.replace('/register')
          this.loading = false
        }
      })
    },
    openLine () {
      window.location = 'https://lin.ee/yAqyOuq'
    },
    login () {
      if (!this.mobile) {
        this.$swal({ icon: 'warning', title: 'ข้อความ!', text: 'โปรดระบุเบอร์มือถือค่ะ!!!' })
        $('#mobile').focus()
      } else if (!this.password) {
        this.$swal({ icon: 'warning', title: 'ข้อความ!', text: 'โปรดระบุ Password ค่ะ!!!' })
        $('#pass').focus()
      } else {
        this.loading = true
        axios({
          method: 'post',
          url: 'https://api.icon1688.co/api/login',
          data: {
            mobile: this.mobile,
            password: this.password
          }
        }).then(async res => {
          if (res.data && !res.data.status) {
            this.$swal({ icon: 'error', title: 'ข้อความ!', text: 'เบอร์โทรศัพท์ / รหัสผ่าน ไม่ถูกต้องค่ะ!!!' })
            $('#mobile').focus()
            this.loading = false
          } else {
            console.log(this.mobile.trim().toLowerCase() + '@icon1688.co', this.password.trim());
            firebase.auth().signInWithEmailAndPassword(this.mobile.trim().toLowerCase() + '@icon1688.co', this.password.trim()).then(async (res2) => {
              if (res2.user) {
                localStorage.setItem('userData', JSON.stringify(res.data))
                this.loading = false
                router.replace('/')
              } else {
                this.error = 'โปรดติดต่อแอดมิน'
              }
              this.loading = false
            }).catch(() => {
              this.$swal({ icon: 'error', title: 'ข้อความ!', text: 'เบอร์โทรศัพท์ / รหัสผ่าน ไม่ถูกต้องค่ะ!!!' })
              this.loading = false
            })
          }
        })
      }
    },
    lineLogin () {
      const url = 'https://access.line.me/oauth2/v2.1/authorize'
      let params = {
        response_type: 'code',
        client_id: this.clientId,
        redirect_uri: this.callbackUri,
        state: Math.random().toString(32).substring(2),
        scope: 'openid profile'
      }
      if (this.addFriend) {
        params = Object.assign(params, {
          prompt: 'consent',
          bot_prompt: 'aggressive'
        })
      }
      window.location.href = `${url}?${queryString.stringify(params)}`
    },
    async getToken (code) {
      const data = qs.stringify({
        grant_type: 'authorization_code',
        code,
        redirect_uri: this.callbackUri,
        client_id: this.clientId,
        client_secret: this.clientSecret
      })
      let response
      await axios({
        method: 'post',
        url: 'https://api.line.me/oauth2/v2.1/token',
        data: data
      }).then(async res => {
        if (res.status !== 200) {
          this.error = 'โปรดติดต่อแอดมิน'
        } else if (res.data) {
          response = res.data
        }
      })
      return response
    },
    async getProfile (token) {
      return await axios.get('https://api.line.me/v2/profile', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    },
    async checkFriend (token) {
      const { data } = await axios.get('https://api.line.me/friendship/v1/status', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return data.friendFlag
    },
    checkShowPassword () {
      var x = document.getElementById('pass')
      if (x.type === 'password') {
        x.type = 'text'
      } else {
        x.type = 'password'
      }
    },
    gotoRegister () {
      router.replace('/register')
    }
  }
}
</script>
<style scoped>
.loginbody {
     padding-top: 4.2rem;
     padding-bottom: 4.2rem;
 }
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}
.container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}
.ewHin {
    background: rgb(0, 187, 0);
    color: rgb(255, 255, 255);
    height: 50px;
    min-width: 180px;
    border-radius: 25px;
    padding-left: 34px;
    padding-right: 34px;
}
.cCsozd {
    display: inline-block;
    padding: 0.5rem 1rem;
    height: 44px;
    font-size: 1em;
    line-height: 1.5;
    border: none;
    border-radius: 0.25rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
}
.ewHin .icon {
    height: 18px;
    margin: 0px 0.5rem;
    vertical-align: sub;
    border: 0px;
}
.circular-spinner {
  margin-top: 30px;
  width: 60px;
  height: 60px;
  /* border: 7px solid white;
  border-top: 7px solid #fc0;
  border-radius: 50%; */
  /* animation: rotate 0.5s linear infinite; */
  position: absolute;
  left: 42%;
  top: 45%;
}
@media (max-width: 990px){
  .circular-spinner {
      top: 30%;
      left: 35%;
  }
}
@media (max-width: 660px){
  .circular-spinner {
      top: 30%;
      left: 20%;
  }
}
@media (max-width: 500px){
  .circular-spinner {
      top: 30%;
      left: 14%;
  }
}
</style>
<style lang="scss" scoped>
@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'],
  input[type='radio'] {
    --active: #275EFE;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #BBC1E1;
    --border-hover: #275EFE;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background .3s, border-color .3s, box-shadow .2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  input[type='checkbox'] {
    &:not(.switch) {
      border-radius: 7px;
      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: .6;
          }
        }
      }
    }
  }
  input[type='radio'] {
    border-radius: 50%;
    &:after {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, .7));
    }
    &:checked {
      --s: .5;
    }
  }
}
</style>